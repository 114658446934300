import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Footer from "../components/footer"

const Sidenav = ({ isRootPath }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(sort: { fields: frontmatter___date, order: ASC }) {
        group(field: frontmatter___category) {
          edges {
            node {
              frontmatter {
                title
                showInNav
              }
              fields {
                slug
              }
              id
            }
          }
          fieldValue
        }
      }
    }
  `)
  const postx = data.allMarkdownRemark.group
  const siteTitle = data.site.siteMetadata.title
  var posts = [...postx].reverse()

  function handleClick() {
    document.getElementById("sidebar").classList.toggle("mobile-sidebar")
    document.getElementById("show-sidebar").classList.toggle("active")
    document.getElementById("hamburger").classList.toggle("hamburger--cross")
  }

  return (
    <>
      <aside
        className={`col-lg-2 d-md-block ${
          isRootPath ? "sidebar--front" : "sidebar"
        }`}
        id="sidebar"
      >
        <div className="sidebar-sticky">
          <ul style={{ listStyle: `none` }}>
            <li className="mb-0">
              <Link
                className="color-white"
                to="/"
                itemProp="url"
                activeClassName="active"
              >
                {siteTitle}
              </Link>
            </li>
            {posts.map(post => {
              const edges = post.edges
              const title = post.fieldValue
              return (
                <li className="mb-0" key={post.fieldValue}>
                  <Link
                    className="color-white"
                    to={"/" + title.replace(/\/|\s+/g, "-").toLowerCase() + "/"}
                    itemProp="url"
                    activeClassName="active"
                  >
                    {title}
                  </Link>
                  <ol style={{ listStyle: `none` }}>
                    {edges.map(edge => {
                      const subtitle = edge.node.frontmatter.title
                      const showInNav = edge.node.frontmatter.showInNav
                      const slug = edge.node.fields.slug
                      return (
                        <li
                          className={`mb-0 ${
                            showInNav === false ? "d-none" : ""
                          }`}
                          key={edge.node.id}
                        >
                          <Link
                            className="color-white"
                            to={slug}
                            itemProp="url"
                            activeClassName="active"
                          >
                            {subtitle}
                          </Link>
                        </li>
                      )
                    })}
                  </ol>
                </li>
              )
            })}
          </ul>
          <Footer />
        </div>
      </aside>
      <button
        id="show-sidebar"
        className={`btn btn-sm ${
          isRootPath ? "show-sidebar--front" : "show-sidebar"
        }`}
        onClick={() => handleClick()}
      >
        <div className="hamburger" id="hamburger">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </button>
    </>
  )
}
export default Sidenav
