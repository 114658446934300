import React from "react"
import Sidenav from "../components/sidenav"
import Header from "../components/header"

const Layout = ({ location, title, children, showNav }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <>
      <Header title={title} />
      <div className={` ${isRootPath ? "container" : "container-fluid"}`}>
        <div className="row">
          <main
            className={` ${
              isRootPath
                ? "col-md-12 col-lg-12 px-4 mt-4 "
                : "col-md-12 ml-14 col-lg-9 px-4 mt-4 "
            }`}
            data-is-root-path={isRootPath}
          >
            <section className="container">
              <main>{children}</main>
              {showNav === true && <Sidenav isRootPath={isRootPath} />}
            </section>
          </main>
        </div>
      </div>
    </>
  )
}

export default Layout
