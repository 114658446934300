import React from "react"

const Footer = () => {
  return (
    <footer className="text-white bottom bottom-content mx-auto">
      © mySecondTeacher {new Date().getFullYear()}
    </footer>
  )
}
export default Footer
