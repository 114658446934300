import React from "react"
import { Link } from "gatsby"
import logo from "../images/mst_logo_2-1.png"

const Header = ({ title }) => {
  return (
    <header className="global-header main_navbar sticky-top bg-white p-3 mb-3">
      <div className="d-flex align-items-center">
        <i className="brand mr-4">
          <Link to="/">
            <img alt="logo" src={logo} className="img-fluid" width="60"></img>
          </Link>
        </i>
        <h3 className="heading mb-0">
          <Link to="/">{title}</Link>
        </h3>
      </div>
    </header>
  )
}
export default Header
